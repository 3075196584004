<template>
  <v-container class="fill-height avisos-container">
    <v-row class="text-center" align="start" justify="center">
      <v-col cols="12" md="8" class="mb-2" align-self="start">
        <h2 class="header font-weight-bold my-2 white--text">
          TOTS ELS SERVEIS DE MOBILITAT QUE NECESSITES
        </h2>

        <v-spacer></v-spacer>

        <p class="subheading font-weight-regular mb-5 dark white--text">
          A Autocars del Penedès t'oferim diferents serveis en mobilitat. Troba
          el servei que necessites o fes-nos una consulta i trobarem una solució
          per a les teves necessitats.
        </p>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <v-row align="start" justify="center">
      <v-col md="5">
        <v-card class="mx-auto">
          <v-img src="../assets/img/serveis-empresa.jpg" height="200px"></v-img>

          <v-card-title>
            SERVEIS EMPRESES
          </v-card-title>

          <v-card-subtitle>
            Coneixi tots els serveis de mobilitat per a empreses.
          </v-card-subtitle>

          <v-card-actions>
            <v-btn color="info darken-2" text>
              + INFO
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="showEmpreses = !showEmpreses">
              <v-icon>{{
                showEmpreses ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showEmpreses">
              <v-divider></v-divider>

              <v-card-text>
                Tant si necessita transport diari per al personal de l'empresa,
                com si el que vol es organitzar un event, podem oferir-li un
                servei de transport adaptat a les necessitats de l'empresa.
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col md="5">
        <v-card class="mx-auto">
          <v-img
            src="../assets/img/serveis-particulars.jpg"
            height="200px"
          ></v-img>

          <v-card-title>
            SERVEIS A PARTICULARS
          </v-card-title>

          <v-card-subtitle>
            Serveis que necessita per a vosté i la seva família.
          </v-card-subtitle>

          <v-card-actions>
            <v-btn color="info darken-2" text>
              + INFO
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="showParticulars = !showParticulars">
              <v-icon>{{
                showParticulars ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showParticulars">
              <v-divider></v-divider>

              <v-card-text>
                Serveis de transport per a events, trasllats a l'aeroport,
                trobades familiars en grups.
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Serveis",

  data: () => ({
    // logged: false
    showEmpreses: false,
    showParticulars: false
  })
};
</script>
